<template>
  <v-card width="100%" class="mb-1">
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-0 pt-1">
          <p class="ma-0 overline grey--text ml-1">Filtros</p>
        </v-col>
        <v-col cols="12" class="pt-1 pb-1">
          <v-text-field
            label="Buscar"
            outlined
            dense
            prepend-inner-icon="search"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-1 pb-1">
          <p class="ma-0 caption">Rango de Precios</p>
          <v-row>
            <v-col cols="6" class="pt-0 pb-1">
              <v-text-field
                label="Menor"
                outlined
                dense
                type="number"
                prepend-inner-icon="attach_money"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="pt-0 pb-1">
              <v-text-field
                label="Mayor"
                outlined
                type="number"
                dense
                prepend-inner-icon="attach_money"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pt-1 pb-1">
          <p class="ma-0 caption">Disponibilidad</p>
          <v-checkbox
            class="ma-0"
            hide-details
            label="Disponible(s)"
          ></v-checkbox>
          <v-checkbox
            class="ma-0"
            hide-details
            label="Sin Existencia"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" class="pt-1 pb-1">
          <p class="ma-0 caption">Estado</p>
          <v-checkbox class="ma-0" hide-details label="Activo"></v-checkbox>
          <v-checkbox class="ma-0" hide-details label="Pausado"></v-checkbox>
        </v-col>

        <v-col cols="12">
          <v-btn color="primary" block>Aplicar</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {};
</script>

<style></style>
