<template>
  <v-card
    color="teal"
    dark
    height="80"
    class="d-flex align-center justify-space-between"
  >
    <div class="px-3">
      <p class="ma-0 caption">Total de productos</p>

      <v-progress-circular
        indeterminate
        size="28"
        v-if="loader"
        class="mt-3"
      ></v-progress-circular>
      <v-slide-y-transition>
        <p class="text-h4 ma-0 pb-0 pl-1" v-if="!loader">
          {{ total_products }}
        </p>
      </v-slide-y-transition>
    </div>
    <div class="px-3">
      <v-icon x-large>category</v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    total_products: {
      type: Number,
      default: 0
    },
    loader: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style></style>
