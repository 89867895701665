<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Inventario
        </h3>
      </v-col>
      <v-col cols="12" class="pb-0">
        <app-total-products
          :loader="loadingTable"
          :total_products="totalProductsInventoried"
        ></app-total-products>
      </v-col>
      <v-col cols="12" class="pb-0">
        <app-total-products-inventoried
          :loader="loadingAnalytics"
          :total_products="productsAnalytics"
        ></app-total-products-inventoried>
      </v-col>
      <v-col
        v-if="false"
        cols="12"
        sm="12"
        md="3"
        lg="3"
        xl="3"
        order-lg="2"
        order-sm="1"
        order-md="2"
        class="pt-0 pb-0"
      >
        <app-inventory-filters></app-inventory-filters>
      </v-col>
      <v-col cols="12" class="pb-0">
        <v-card width="100%">
          <v-container class="pb-0 pt-4">
            <v-text-field
              label="Buscar"
              outlined
              dense
              prepend-inner-icon="search"
              hide-details
              v-model="searchProduct"
            ></v-text-field>
          </v-container>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="12" class="pt-1">
                <v-data-table
                  :headers="headers"
                  :items="productList"
                  :items-per-page="20"
                  :search="searchProduct"
                  :loading="loadingTable"
                  @click:row="goToDetail"
                  @update:page="$vuetify.goTo(0)"
                  class="selected-row"
                  loading-text="Estamos preparando tus datos..."
                >
                  <template v-slot:item.branch_office="{ item }">
                    <v-chip
                      :color="hasLowInventory(item.branch_office)"
                      v-if="hasLowInventory(item.branch_office) != 'na'"
                      >{{
                        listRenderTotalInventory(item.branch_office)
                      }}</v-chip
                    >
                  </template>
                  <template v-slot:item.cost_price="{ item }">
                    $ {{ item.cost_price }}
                  </template>
                  <template v-slot:item.price="{ item }">
                    $ {{ item.price }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InventoryFilters from "@/components/inventory/Filters";
import TotalProductsInventoried from "../../components/inventory/TotalProductsInventoried";
import TotalProducts from "../../components/inventory/TotalProducts";
import { mapActions, mapState } from "vuex";
import { database } from "@/config/firebase";
import { errorMessage } from "@/services/MessageService";

export default {
  data() {
    return {
      // :: Data
      headers: [
        { text: "Producto", value: "name_product", align: "center" },
        { text: "SKU", value: "barcode", align: "center" },
        { text: "Costo", value: "cost_price", align: "center" },
        { text: "Precio", value: "price", align: "center" },
        { text: "Inventario", value: "branch_office", align: "center" }
      ],
      // :: New data
      productDatabaseRef: null,
      searchProduct: null,
      loadingTable: false,
      productInventoryLocalDatabaseRef: null,
      loadingAnalytics: true,
      productsAnalytics: 0,
      totalProductsInventoried: 0
    };
  },
  computed: {
    ...mapState("user", ["components", "data"]),
    ...mapState("business", ["currentBranchID"]),
    ...mapState("pointSale", ["productList", "totalInventoryAnalytics"])
  },
  beforeMount() {
    this.getInventoryData();
    this.getProductAnalytics();
  },
  methods: {
    ...mapActions("pointSale", ["saveProductsList"]),
    async getInventoryData() {
      this.loadingTable = true;
      try {
        this.productDatabaseRef = await database(`store`);
        this.productDatabaseRef.on("value", async snapshot => {
          if (snapshot.exists()) {
            const productsData = snapshot.val();
            const productList = Object.keys(productsData).map(
              key => productsData[key]
            );
            this.totalProductsInventoried = productList.length;
            this.saveProductsList(productList);
            this.loadingTable = false;
          } else {
            this.totalProductsInventoried = 0;
            this.saveProductsList([]);
            this.loadingTable = false;
          }
        });
      } catch (error) {
        errorMessage({
          message:
            "Upss algo salio mal al obtener la información del dashboard. Code:" +
            error
        });
      }
    },
    async getProductAnalytics() {
      this.loadingAnalytics = true;
      try {
        this.productInventoryLocalDatabaseRef = await database(
          `branch_offices/${this.currentBranchID}/analytics/store/inventory`
        );
        this.productInventoryLocalDatabaseRef.on("value", async snapshot => {
          if (snapshot.exists()) {
            this.productsAnalytics = snapshot.val().total_products;
            this.loadingAnalytics = false;
          } else {
            this.productsAnalytics = 0;
            this.loadingAnalytics = false;
          }
        });
      } catch (error) {
        errorMessage({
          message:
            "Upss algo salio mal al obtener los analiticos del inventario Code:" +
            error
        });
      }
    },
    async removeListenerDatabase() {
      await this.productDatabaseRef.off("value");
      this.productDatabaseRef = null;
      await this.productInventoryLocalDatabaseRef.off("value");
      this.productInventoryLocalDatabaseRef = null;
    },
    listRenderTotalInventory(branchOfficeData) {
      if (branchOfficeData) {
        if (branchOfficeData[this.currentBranchID]) {
          if (branchOfficeData[this.currentBranchID].inventory) {
            const inventory =
              branchOfficeData[this.currentBranchID].inventory.total_inventory;
            return inventory;
          }
          return "N/A";
        }
      }
    },
    goToDetail(item) {
      this.$router.push({
        name: "product",
        params: { productID: item.barcode }
      });
    },
    hasLowInventory(branchOfficeData) {
      if (branchOfficeData) {
        if (branchOfficeData[this.currentBranchID]) {
          if (branchOfficeData[this.currentBranchID].inventory) {
            const totalInventory =
              branchOfficeData[this.currentBranchID].inventory.total_inventory;
            const minInventory =
              branchOfficeData[this.currentBranchID].inventory.min_inventory;
            if (parseInt(totalInventory) <= parseInt(minInventory))
              return "error lighten-1";
            return "";
          }
          return "na";
        }
      }
    }
  },
  beforeDestroy() {
    this.loadingTable = false;
    this.removeListenerDatabase();
  },
  components: {
    AppInventoryFilters: InventoryFilters,
    AppTotalProductsInventoried: TotalProductsInventoried,
    AppTotalProducts: TotalProducts
  }
};
</script>

<style scoped>
.selected-row {
  cursor: pointer;
}
</style>
